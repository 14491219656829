import { Id, Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useGetProductTemplate from '~/src/pages/category/[slug]/product/[slug]/styles/useGetProductTemplate'
import ProductDetails from '~/src/components/products/product-details'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import useLogViewItem from '~/src/hooks/analytics/ecommerce/useLogViewItem'
import useCheckCategoryEffect from '~/src/hooks/categories/useCheckCategoryEffect'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CATEGORY_BY_ID_SELECTOR, CATEGORY_ID_STATE } from '~/src/stores/categories'
import { PRODUCT_ID_SELECTOR, PRODUCT_ID_STATE } from '~/src/stores/product'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const ProductPage = () => {
  const pageName: keyof typeof RoutesEnum = 'PRODUCT'
  const styles = useGetProductTemplate()
  const { log: logViewItem } = useLogViewItem()
  const params = useParams()
  const setProductIdState = useSetEoState(PRODUCT_ID_STATE)
  const [ categoryId, setCategoryIdState ] = useEoState(CATEGORY_ID_STATE)
  const categorySelected = useEoValue(CATEGORY_BY_ID_SELECTOR)
  const product = useEoValue<Product | null>(PRODUCT_ID_SELECTOR)

  useCheckCategoryEffect(categoryId)

  useEffect(() => {
    return () => {
      setProductIdState(null)
      setCategoryIdState(null)
    }
  }, [])

  useEffect(() => {
    params.productId && setProductIdState(params.productId)
  }, [ params ])

  /**
   * This useEffect is used when we show the page of a product and this product comes from a child category
   * in order to have an item set within the sub-header (categories list) we need to set the parentId of the category
   * if we don't do that no categories will be selected for the displayed product
   */
  useEffect(() => {
    const id: Id | undefined = categorySelected?.parentId || categorySelected?.id

    if (!id || id === categoryId) {
      return
    }

    setCategoryIdState(id)
  }, [ categorySelected ])

  useEffect(() => {
    if (!product?.categoryId) {
      return
    }

    setCategoryIdState(product.categoryId)
    logViewItem(product)
  }, [ product ])

  if (!product) {
    return null
  }

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName} />
      <Box sx={styles?.getPageSx()}>
        <ProductDetails product={product}/>
      </Box>
    </SlideFromRight>
  )
}

export default ProductPage
